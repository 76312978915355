import React, { Component } from 'react';
import { connect } from 'react-redux';
import ToolContainer from './ToolContainer';


const PERMISSIONS = [
    {
        "PERMISSION": "CREATE_INSTANT_INVITE",
        "VALUE": "0x0000000000000001",
        "Description": "Allows creation of instant invites",
        "category": "General"
    },
    {
        "PERMISSION": "KICK_MEMBERS",
        "VALUE": "0x0000000000000002",
        "Description": "Allows kicking members",
        "category": "Moderation"
    },
    {
        "PERMISSION": "BAN_MEMBERS",
        "VALUE": "0x0000000000000004",
        "Description": "Allows banning members",
        "category": "Moderation"
    },
    {
        "PERMISSION": "ADMINISTRATOR",
        "VALUE": "0x0000000000000008",
        "Description": "Allows all permissions and bypasses channel permission overwrites",
        "category": "Moderation"
    },
    {
        "PERMISSION": "MANAGE_CHANNELS",
        "VALUE": "0x0000000000000010",
        "Description": "Allows management and editing of channels",
        "category": "Moderation"
    },
    {
        "PERMISSION": "MANAGE_GUILD",
        "VALUE": "0x0000000000000020",
        "Description": "Allows management and editing of the guild",
        "category": "Moderation"
    },
    {
        "PERMISSION": "ADD_REACTIONS",
        "VALUE": "0x0000000000000040",
        "Description": "Allows for the addition of reactions to messages",
        "category": "General"
    },
    {
        "PERMISSION": "VIEW_AUDIT_LOG",
        "VALUE": "0x0000000000000080",
        "Description": "Allows for viewing of audit logs",
        "category": "Moderation"
    },
    {
        "PERMISSION": "PRIORITY_SPEAKER",
        "VALUE": "0x0000000000000100",
        "Description": "Allows for using priority speaker in a voice channel",
        "category": "Voice"

    },
    {
        "PERMISSION": "STREAM",
        "VALUE": "0x0000000000000200",
        "Description": "Allows the user to go live",
        "category": "Voice"
    },
    {
        "PERMISSION": "VIEW_CHANNEL",
        "VALUE": "0x0000000000000400",
        "Description": "Allows guild members to view a channel, which includes reading messages in text channels and joining voice channels",
        "category": "General"
    },
    {
        "PERMISSION": "SEND_MESSAGES",
        "VALUE": "0x0000000000000800",
        "Description": "Allows for sending messages in a channel and creating threads in a forum (does not allow sending messages in threads)",
        "category": "General"
    },
    {
        "PERMISSION": "SEND_TTS_MESSAGES",
        "VALUE": "0x0000000000001000",
        "Description": "Allows for sending of /tts messages",
        "category": "General"
    },
    {
        "PERMISSION": "MANAGE_MESSAGES",
        "VALUE": "0x0000000000002000",
        "Description": "Allows for deletion of other users messages",
        "category": "Moderation"
    },
    {
        "PERMISSION": "EMBED_LINKS",
        "VALUE": "0x0000000000004000",
        "Description": "Links sent by users with this permission will be auto-embedded",
        "category": "General"
    },
    {
        "PERMISSION": "ATTACH_FILES",
        "VALUE": "0x0000000000008000",
        "Description": "Allows for uploading images and files",
        "category": "General"
    },
    {
        "PERMISSION": "READ_MESSAGE_HISTORY",
        "VALUE": "0x0000000000010000",
        "Description": "Allows for reading of message history",
        "category": "General"
    },
    {
        "PERMISSION": "MENTION_EVERYONE",
        "VALUE": "0x0000000000020000",
        "Description": "Allows for using the @everyone tag to notify all users in a channel, and the @here tag to notify all online users in a channel",
        "category": "General"
    },
    {
        "PERMISSION": "USE_EXTERNAL_EMOJIS",
        "VALUE": "0x0000000000040000",
        "Description": "Allows the usage of custom emojis from other servers",
        "category": "General"
    },
    {
        "PERMISSION": "VIEW_GUILD_INSIGHTS",
        "VALUE": "0x0000000000080000",
        "Description": "Allows for viewing guild insights",
        "category": "Moderation"
    },
    {
        "PERMISSION": "CONNECT",
        "VALUE": "0x0000000000100000",
        "Description": "Allows for joining of a voice channel",
        "category": "Voice"
    },
    {
        "PERMISSION": "SPEAK",
        "VALUE": "0x0000000000200000",
        "Description": "Allows for speaking in a voice channel",
        "category": "Voice"
    },
    {
        "PERMISSION": "MUTE_MEMBERS",
        "VALUE": "0x0000000000400000",
        "Description": "Allows for muting members in a voice channel",
        "category": "Voice"
    },
    {
        "PERMISSION": "DEAFEN_MEMBERS",
        "VALUE": "0x0000000000800000",
        "Description": "Allows for deafening of members in a voice channel",
        "category": "Voice"
    },
    {
        "PERMISSION": "MOVE_MEMBERS",
        "VALUE": "0x0000000001000000",
        "Description": "Allows for moving of members between voice channels",
        "category": "Voice"
    },
    {
        "PERMISSION": "USE_VAD",
        "VALUE": "0x0000000002000000",
        "Description": "Allows for using voice-activity-detection in a voice channel",
        "category": "Voice"
    },
    {
        "PERMISSION": "CHANGE_NICKNAME",
        "VALUE": "0x0000000004000000",
        "Description": "Allows for modification of own nickname",
        "category": "General"
    },
    {
        "PERMISSION": "MANAGE_NICKNAMES",
        "VALUE": "0x0000000008000000",
        "Description": "Allows for modification of other users nicknames",
        "category": "Moderation"
    },
    {
        "PERMISSION": "MANAGE_ROLES",
        "VALUE": "0x0000000010000000",
        "Description": "Allows management and editing of roles",
        "category": "Moderation"
    },
    {
        "PERMISSION": "MANAGE_WEBHOOKS",
        "VALUE": "0x0000000020000000",
        "Description": "Allows management and editing of webhooks",
        "category": "Moderation"

    },
    {
        "PERMISSION": "MANAGE_GUILD_EXPRESSIONS",
        "VALUE": "0x0000000040000000",
        "Description": "Allows management and editing of emojis, stickers, and soundboard sounds",
        "category": "Moderation"
    },
    {
        "PERMISSION": "USE_APPLICATION_COMMANDS",
        "VALUE": "0x0000000080000000",
        "Description": "Allows members to use application commands, including slash commands and context menu commands.",
        "category": "General"
    },
    {
        "PERMISSION": "REQUEST_TO_SPEAK",
        "VALUE": "0x0000000100000000",
        "Description": "Allows for requesting to speak in stage channels. (This permission is under active development and may be changed or removed.)",
        "category": "Voice"
    },
    {
        "PERMISSION": "MANAGE_EVENTS",
        "VALUE": "0x0000000200000000",
        "Description": "Allows for creating, editing, and deleting scheduled events",
        "category": "Moderation"
    },
    {
        "PERMISSION": "MANAGE_THREADS",
        "VALUE": "0x0000000400000000",
        "Description": "Allows for deleting and archiving threads, and viewing all private threads",
        "category": "Moderation"
    },
    {
        "PERMISSION": "CREATE_PUBLIC_THREADS",
        "VALUE": "0x0000000800000000",
        "Description": "Allows for creating public and announcement threads",
        "category": "General"
    },
    {
        "PERMISSION": "CREATE_PRIVATE_THREADS",
        "VALUE": "0x0000001000000000",
        "Description": "Allows for creating private threads",
        "category": "General"
    },
    {
        "PERMISSION": "USE_EXTERNAL_STICKERS",
        "VALUE": "0x0000002000000000",
        "Description": "Allows the usage of custom stickers from other servers",
        "category": "General"
    },
    {
        "PERMISSION": "SEND_MESSAGES_IN_THREADS",
        "VALUE": "0x0000004000000000",
        "Description": "Allows for sending messages in threads",
        "category": "General"
    },
    {
        "PERMISSION": "USE_EMBEDDED_ACTIVITIES",
        "VALUE": "0x0000008000000000",
        "Description": "Allows for using Activities (applications with the EMBEDDED flag) in a voice channel",
        "category": "Voice"
    },
    {
        "PERMISSION": "MODERATE_MEMBERS",
        "VALUE": "0x0000010000000000",
        "Description": "Allows for timing out users to prevent them from sending or reacting to messages in chat and threads, and from speaking in voice and stage channels",
        "category": "Moderation"
    },
    {
        "PERMISSION": "USE_SOUNDBOARD",
        "VALUE": "0x0000040000000000",
        "Description": "Allows for using soundboard in a voice channel",
        "category": "Voice"
    },
    {
        "PERMISSION": "USE_EXTERNAL_SOUNDS",
        "VALUE": "0x0000200000000000",
        "Description": "Allows the usage of custom soundboard sounds from other servers",
        "category": "Voice"
    },
    {
        "PERMISSION": "SEND_VOICE_MESSAGES",
        "VALUE": "0x0000400000000000",
        "Description": "Allows sending voice messages",
        "category": "Voice"
    }
];

const FAQS = [
    {
        question: "How to give permissions in Discord?",
        answer:
            "To give permissions in Discord, you typically need to be the server owner or have the appropriate role with the 'Manage Server' permission. You can then go to the server settings, select 'Roles,' and configure permissions for each role or member."
    },
    {
        question: "How to give someone permissions on Discord?",
        answer:
            "To give someone permissions on Discord, you can create a role with the desired permissions and assign it to the user. Alternatively, you can directly manage permissions for individual users in the server settings."
    },
    {
        question: "What does the slash mean in Discord permissions?",
        answer:
            "In Discord permissions, the slash ('/') is used to separate permission types. For example, 'View Channels' and 'Text Permissions' might be separated by a slash, indicating that the permission includes both."
    },
    {
        question: "How to set up permissions in Discord?",
        answer:
            "To set up permissions in Discord, go to the server settings, select 'Roles,' and configure permissions for each role. You can define who can do what within the server, such as managing channels, sending messages, or kicking members."
    },
    {
        question: "How to give pin permissions in Discord?",
        answer:
            "Pinning messages is usually available to all members with the 'Read Messages' permission in a text channel. If a member has this permission, they can pin their own messages or messages from others."
    },
    {
        question: "How to change bot permissions in Discord?",
        answer:
            "To change bot permissions in Discord, you need the 'Manage Roles' permission. Create a role with the desired permissions, assign it to the bot, and adjust the permissions as needed."
    },
    {
        question: "What permissions should mods have in Discord?",
        answer:
            "The permissions for moderators in Discord can vary depending on the server's needs, but common permissions include managing messages, kicking and banning members, and possibly managing channels."
    },
    {
        question: "How to give bot permissions in Discord?",
        answer:
            "To give bot permissions in Discord, create a role with the desired permissions, and assign that role to the bot. You can then customize the bot's permissions by modifying the role's settings."
    },
    {
        question: "How to give someone manage server permissions on Discord?",
        answer:
            "To give someone 'Manage Server' permissions in Discord, you need to assign them a role with that permission enabled. Alternatively, you can directly grant the permission to specific users if you have the necessary authority."
    },
    {
        question: "How to give mod permissions in Discord?",
        answer:
            "To give moderator permissions in Discord, assign the appropriate roles with the desired permissions to the moderators or use Discord's built-in moderator roles."
    },
    {
        question: "How to set up Discord permissions?",
        answer:
            "To set up Discord permissions, navigate to the server settings, choose 'Roles,' and configure permissions for each role based on your server's needs."
    },
    {
        question: "How to remove invite permissions on Discord?",
        answer:
            "To remove invite permissions on Discord, adjust the role's permissions for creating and managing invites in the server settings. Remove the 'Create Invite' permission for the specific role."
    },
    {
        question: "How to edit bot permissions in Discord?",
        answer:
            "To edit bot permissions in Discord, go to the role assigned to the bot in the server settings and modify the role's permissions as needed."
    },
    {
        question: "How to grant Discord administrator permissions for sound?",
        answer:
            "Discord doesn't have specific 'sound' permissions. You can grant 'Administrator' permissions to a role or user, which provides extensive control over the server."
    },
    {
        question: "How to give bots permissions on Discord?",
        answer:
            "To give bots permissions on Discord, create a role with the desired permissions and assign it to the bot."
    },
    {
        question: "How to give Discord bot permissions?",
        answer:
            "You can give Discord bot permissions by assigning a role with the desired permissions to the bot or by directly modifying the bot's permissions."
    },
    {
        question: "How to hack Discord permissions?",
        answer:
            "Hacking Discord permissions is against Discord's terms of service and is illegal. It can result in severe consequences, including account bans and legal action."
    },
    {
        question: "What permissions to give Discord bots?",
        answer:
            "The permissions to give to Discord bots depend on the bot's functionality. Typically, you should grant only the permissions required for the bot to perform its intended tasks. Be cautious about giving unnecessary permissions."
    },
    {
        question: "How to give webhook permissions in Discord?",
        answer:
            "Webhooks in Discord inherit permissions from the channels they are used in. To control webhook permissions, adjust the channel's permissions where the webhook is active."
    }
];


export class PermissionsCalc extends Component {

    constructor(props) {
        super(props);

        this.state = {
            values: {},
            search: ""
        };
    }

    calculateBitField = () => {
        var values = this.state.values;

        var bitfield = 0;
        Object.keys(values).forEach(key => {
            if (values[key]) {
                bitfield += parseInt(PERMISSIONS.find(perm => perm.PERMISSION === key).VALUE);
            };
        });

        return bitfield;
    };


    renderPermissions = () => {
        var perms = [];

        var permissions_object = {

        };

        var search = this.state.search.toLowerCase();
        // Replace spaces with _ for search
        search = search.replace(" ", "_");

        // Sort permissions into categories;

        PERMISSIONS.forEach(perm => {
            if (!permissions_object[perm.category]) {
                permissions_object[perm.category] = [];
            }

            // Check for search
            if (perm.PERMISSION.toLowerCase().includes(search) || perm.Description.toLowerCase().includes(search)) {
                permissions_object[perm.category].push(<div className="relative flex items-start ">
                    <div className="flex h-6 items-center px-4">
                        <input
                            id={perm.PERMISSION}
                            aria-describedby="comments-description"
                            name={perm.PERMISSION}
                            type="checkbox"
                            checked={this.state.values[perm.PERMISSION] ? this.state.values[perm.PERMISSION] : false}
                            onChange={(e) => {
                                var values = this.state.values;
                                values[perm.PERMISSION] = e.target.checked;
                                this.setState({ values });
                            }}
                            className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                    </div>
                    <div className="ml-3 text-sm leading-6 hover:cursor-pointer hover:opacity-80 flex flex-col">
                        <label htmlFor={perm.PERMISSION} className="font-bold hover:cursor-pointer text-xl text-white overflow-hidden">
                            {perm.PERMISSION}
                        </label>
                        <label htmlFor={perm.PERMISSION} className="hover:cursor-pointer muted-text text-left">
                            {perm.Description}
                        </label>
                    </div>
                </div>);
            }
        });

        Object.keys(permissions_object).forEach(category => {
            if (permissions_object[category].length == 0) return;
            perms.push(
                <div className='mb-5'>
                    <h2 className='text-3xl font-bold text-white mb-3'>{category}</h2>
                    <fieldset>
                        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-5">

                            {permissions_object[category]}
                        </div>
                    </fieldset>
                </div>
            );
        });



        return perms;
    };
    render() {
        return (
            <ToolContainer faqs={FAQS} title={"Discord Permissions Calculator"}>
                <div className=''>
                    <div className="text-center text-white flex justify-center flex-col mb-3">
                        <img className='h-[60px]' src={process.env.PUBLIC_URL + '/img/logo-red.png'}></img>
                        <h1 className='text-5xl font-bold'>Discord Permissions Calculator</h1>
                        <p className='muted-text'>Calculate the permissions integer for OAuth invite links by selecting individual permissions</p>
                    </div>


                    <div className='mb-5 flex flex-col md:flex-row items-center'>
                        <h2 className='text-4xl font-bold text-white'>Permissions: <span className="text-red">{this.calculateBitField()}</span></h2>
                        <div className='md:ml-auto gap-y-4 md:gap-y-0 mt-4 md:mt-0 md:flex-row flex flex-col gap-x-2'>
                            <button className='btn btn-red font-bold' onClick={() => {
                                var values = {};
                                PERMISSIONS.forEach(perm => {
                                    values[perm.PERMISSION] = true;
                                });
                                this.setState({ values });
                            }}>Select All</button>

                            <button className='btn btn-gray font-bold' onClick={() => {

                                this.setState({ values: {} });
                            }}>Deselect All</button>
                            <input
                                type="text"
                                name="text"
                                id="text"
                                value={this.state.search}
                                onChange={(e) => this.setState({ search: e.target.value })}
                                className="block w-[250px] rounded-md border-0 py-2.5 px-2 text-xl text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                placeholder="Search..."
                            />
                        </div>
                    </div>


                    <div>

                        <fieldset>
                            <legend className="sr-only">Notifications</legend>
                            <div className="">
                                {
                                    this.renderPermissions()
                                }


                            </div>
                        </fieldset>


                    </div>

                </div>
            </ToolContainer>
        );
    }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = {};

export default PermissionsCalc;